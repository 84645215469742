<style lang="less" scoped>
  .medicine-sale {
    display: flex;
    flex-direction: column;
  }
  .form {
    padding-top: 15px;
    background-color: #FFF;
    margin-bottom: 10px;
  }
  .block {
    display: flex;
    flex: 1;
    height: 0;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    .left {
      display: flex;
      flex-direction: column;
      flex: 4.5;
    }
    .right {
      background-color: #FFF;
      margin-left: 10px;
      flex: 5.5;
    }
  }
  .group-list {
    overflow: auto;
    flex: 1;
    height: 0;
    position: relative;
    border-radius: 5px;
    .group + .group {
      margin-top: 10px;
    }
  }
  .bottom {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #FFF;
    border: 1px solid #EEE;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .submit-btn {
    border-radius: 20px;
    cursor: pointer;
    color: #FFF;
    background-color: rgb(86, 125, 253);
    padding: 5px 30px;
  }
</style>

<template>
  <div class="medicine-sale">
    <div class="form">
      <fm-form inline>
        <fm-form-item label="姓名：" style="margin-bottom: 10px;">
          <fm-input-new :readonly="patientsData.id !== null" placeholder="请输入姓名" v-model="patientsData.name">
            <i @click="status.query = true" slot="append" style="cursor: pointer; margin: 0 10px;" class="iconfont icon-a-zujian215"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item label="性别：" style="margin-bottom: 10px;">
          <fm-radio-group v-model="patientsData.sex">
            <fm-radio :disabled="patientsData.id !== null" label="男" value="男"></fm-radio>
            <fm-radio :disabled="patientsData.id !== null" label="女" value="女"></fm-radio>
          </fm-radio-group>
        </fm-form-item>
        <fm-form-item label="年龄：" style="margin-bottom: 10px;">
          <fm-input-number :readonly="patientsData.id !== null" v-model="patientsData.age" :min="0" />
        </fm-form-item>
        <fm-form-item label="手机：" style="margin-bottom: 10px;">
          <fm-input-new :readonly="patientsData.id !== null" placeholder="请输入手机号" v-model="patientsData.phone" />
        </fm-form-item>
        <fm-form-item style="margin-bottom: 10px;">
          <fm-btn @click="resetPatientsData" v-if="patientsData.id !== null">重置</fm-btn>
        </fm-form-item>
      </fm-form>
    </div>
    <div class="block">
      <div class="left">
        <div class="group-list scrollbar">
          <div v-if="!hasData" class="group" style="border: 1px solid #EEE;height: 100%;background-color: #FFF;">
            <div style="display: flex;padding: 10px;padding-bottom: 0;align-items: center;color: #999;font-size: 15px;">已选药品</div>
            <div class="empty">
              <img style="margin: 50px auto 50px auto;width: 200px;display: block;" src="/static/images/no-data.png" />
            </div>
          </div>
          <template v-else>
            <template v-for="(group, i) in groups">
              <template v-if="group.list.length">
                <BagsGroup class="group" v-if="group.type === '中药颗粒袋装'" :data="group" :index="i" :key="i + group.type" />
                <BottledGroup class="group" v-if="group.type === '中药颗粒瓶装'" :data="group" :index="i" :key="i + group.type" />
                <MedicineGroup class="group" v-if="group.type === '中西成药'" :data="group" :index="i" :key="i + group.type" />
                <SlicesGroup class="group" v-if="group.type === '中药饮片'" :data="group" :index="i" :key="i + group.type" />
              </template>
            </template>
          </template>
        </div>
        <div class="bottom">
          <div>
            <div style="margin-bottom: 5px;color: #999;">已选药品{{medichineNum}}种</div>
            <div style="color: #000;font-size: 18px;font-weight: 800;">
              金额总价：<span style="color: red;">{{medichineTotalPrice}}</span><span style="color: red;font-size: 15px;margin-left: 2px;">元</span>
            </div>
          </div>
          <div @click="submit" class="submit-btn">去结算</div>
        </div>
      </div>
      <div class="right">
        <MedicineSearchList ref="MedicineSearchList" :hide="['infusion']" @choose="onMedichineChoose" />
      </div>
    </div>
    <fm-modal title="患者查询" v-model="status.query">
      <PatientsQuery @query="onPatientsQuery" />
    </fm-modal>

    <fm-modal :mask-closable="false" title="共享药房" v-model="status.purchase" width="1000px">
      <share-medicine-purchase @submit="data => (submit(false, data), status.purchase = false)" :data="purchaseList" />
    </fm-modal>

    <fm-modal :mask-closable="false" title="收款" v-model="status.pay" width="650px">
      <pay @cancel="payCancel" @payOver="payOver" :key="order.id" type="get" :order-id="order.id" v-if="order && order.id"></pay>
    </fm-modal>
  </div>
</template>

<script>
import { mainbodyMedicineRequest, orderRequest, patientRequest } from '@/api'
import PatientsQuery  from '@/views/patients/details/query.vue'
import MedicineSearchList from '@/views/medicine/search/block.vue'
import { Prescription } from '@/views/patients/details/prescription/tables/lib.js'
import ShareMedicinePurchase from '@/views/medicine/share/purchase.vue'
import Pay from '@/views/order/detail/pay'

import BagsGroup from './groups/bags.vue'
import BottledGroup from './groups/bottled.vue'
import MedicineGroup from './groups/medicine.vue'
import SlicesGroup from './groups/slices.vue'

function getDefaultPatientsData () {
  return {
    id: null,
    name: null,
    age: 0,
    sex: '男',
    phone: null
  }
}

export default {
  components: { PatientsQuery, MedicineSearchList, BagsGroup, BottledGroup, MedicineGroup, SlicesGroup, ShareMedicinePurchase, Pay },
  data () {
    return {
      patientsData: getDefaultPatientsData(),
      status: {
        query: false,
        purchase: false,
        pay: false
      },
      groups: [
        new Prescription('中药颗粒袋装'),
        new Prescription('中药饮片'),
        new Prescription('中药颗粒瓶装'),
        new Prescription('中西成药'),
        new Prescription('输液')
      ],
      purchaseList: [],
      order: null,
      addPharmacyMedichineList: []
    }
  },
  computed: {
    hasData () {
      return this.groups.find(v => v.list.length > 0) !== undefined
    },
    medichineNum () {
      return this.groups.reduce((a, b) => {
        return a + b.list.length
      }, 0)
    },
    medichineTotalPrice () {
      return this.groups.reduce((a, b) => {
        return a + (b.list.reduce((c, d) => c + d.volume * d.price, 0)) * b.timesNum
      }, 0).toFixed(2)
    }
  },
  methods: {
    reset () {
      this.groups = [
        new Prescription('中药颗粒袋装'),
        new Prescription('中药饮片'),
        new Prescription('中药颗粒瓶装'),
        new Prescription('中西成药'),
        new Prescription('输液')
      ]
    },
    async payCancel () {
      this.status.pay = false
      this.reset()
    },
    async payOver () {
      this.status.pay = false
      this.reset()

      if (this.addPharmacyMedichineList && this.addPharmacyMedichineList.length) {
        const promises = this.addPharmacyMedichineList.map(item => {
          return new Promise(resolve => {
            mainbodyMedicineRequest.add(item).then(() => {
              resolve({ status: true, item, msg: '成功' })
            }).catch(err => {
              resolve({ status: false, item, msg: err.message })
            })
          })
        })

        const result = await Promise.all(promises)
        const errList = result.filter(v => !v.status)
        const successList = result.filter(v => v.status)

        this.$dialog.info({
          title: '药房提示',
          content: '<span>' + successList.length + '种药已成功添加至药房中</span>' + (errList.length ? ('<br /><span style="color: red;">' + errList.length + '种药添加失败或已存在！<span>') : '')
        })

        this.$refs.MedicineSearchList.loadData()
      }
    },
    async submit (checkPurchase = true, purchaseData = null) {
      const medicineIds = this.groups.map(group => group.list.map(v => v.medicineId)).flat()

      if (!medicineIds.length) {
        return this.$notice.warning('请选择药品'), false
      }

      let medicineList = []
      if (checkPurchase) {
        medicineList = await mainbodyMedicineRequest.getBaseAll({
          medicineIds: medicineIds.join(',')
        })
      }

      this.addPharmacyMedichineList = []

      this.purchaseList = medicineList.filter(v => !v.inventory || v.inventory.totalVolume <= 0)
      if (this.purchaseList.length && checkPurchase) {
        this.status.purchase = true
      } else {
        if (!this.patientsData.id) {
          this.patientsData.id = await patientRequest.add(this.patientsData)
        }

        const orderId = await orderRequest.prescription({
          patientId: this.patientsData.id,
          details: this.groups.map(group => {
            return group.list.map(item => {
              const volume = item.volume * group.timesNum
              return {
                medicineId: item.medicineId,
                volume: volume,
                price: item.price,
                total: volume * item.price
              }
            })
          }).flat()
        })

        const getVolume = (medicineId) => {
          let backpackMedicineInfo = null, backpackGroupInfo = null
          for (let i = 0; i < this.groups.length; i++) {
            for (let l = 0; l < this.groups[i].list.length; l++) {
              if (this.groups[i].list[l].medicineId === medicineId) {
                backpackMedicineInfo = this.groups[i].list[l];break
              }
            }
            if (backpackMedicineInfo) {
              backpackGroupInfo = this.groups[i];break
            }
          }
          return backpackGroupInfo.timesNum * backpackMedicineInfo.volume
        }

        // 共享药房采购
        if (purchaseData) {
          if (purchaseData.type === 'share') {
            await orderRequest.add({
              saleMainbodyId: purchaseData.saleMainbodyId,
              mainbodyMedicineGroupId: purchaseData.mainbodyMedicineGroupId,
              details: purchaseData.details.map(item => {
                const volume = getVolume(item.medicine.id)
                return {
                  volume: volume,
                  medicineId: item.medicine.id,
                  price: item.share.price,
                  total: item.share.price * volume
                }
              }),
              sourceType: 'patients',
              sourceDataId: this.patientsData.id
            })
            this.$notice.success('共享药房采购单已生成')
          }

          // 不存在的药品自动添加到药房
          purchaseData.nonexistent.forEach(item => {
            this.addPharmacyMedichineList.push({
              medicineId: item.id,
              avgPurchasePrice: item.price,
              price: item.price,
              warningValue: null,
              productionBatch: null,
              productionDate: null,
              expiredDate: null,
              volume: 0
            })
          })
        }

        const orders = await orderRequest.get({ id: orderId })
        this.order = orders.pop()

        this.status.pay = true
      }
    },
    resetPatientsData () {
      this.patientsData = getDefaultPatientsData()
    },
    onMedichineChoose (medichine, tag) {
      const index = this.groups.findIndex(v => v.type === tag.value)
      this.groups[index].addMedichine(medichine, tag)
      console.log(this.groups)
    },
    onPatientsQuery (data) {
      this.patientsData = data
      this.status.query = false
    }
  },
}
</script>
