<style lang="less" scoped>
  @import url('./styles.less');
</style>

<template>
  <div class="medicine-group slices-medicine-group" v-if="data && data.list">
    <div class="title">
      <span class="num" v-if="index !== null">{{index + 1}}</span>
      <span class="type-name">中西成药</span>
    </div>
    <div class="items">
      <div class="item" v-for="(item, key) in data.list" :key="key">
        <div class="name">{{item.medichine.mainbodyMedicine ? item.medichine.mainbodyMedicine.medicineName : item.medichine.name}}</div>
        <div class="row">
          <div class="field-item">
            <span class="field">价格：</span>
            <span class="price">¥{{item.amount = (item.volume * item.price).toFixed(2)}}</span>
            <span class="field">（¥{{item.price || 0}} * {{item.volume}}）</span>
          </div>
          <div class="field-item">
            <span class="field">药量：</span>
            <InputNumber size="small" :min="1" v-model="item.volume" controls-outside />
          </div>
        </div>
        <div class="params">
          <div style="flex: 1;">
            <div class="row">
              <div class="field-item">
                <span class="field" style="width: 70px;">用法：</span>
                <Select style="width: 175px;" size="small" clearable filterable placeholder="请选择用法" v-model="item.medicineUsage">
                  <Option v-for="(t, i) in usage" :key="i" :value="t" :label="t"></Option>
                </Select>
              </div>
            </div>
            <div class="row">
              <div class="field-item">
                <span class="field">用药频率：</span>
                <Select style="width: 175px;" v-model="item.usageFrequency" size="small" clearable filterable placeholder="请选择用药频率">
                  <Option v-for="(t, i) in usageFrequency" :key="i" :value="t" :label="t"></Option>
                </Select>
              </div>
            </div>
            <div class="row">
              <div class="field-item">
                <div class="field">每次用量：</div>
                <InputNumber size="small" style="width: 100px;" controls-outside :min="0" v-model="item.timesVolume[0]" />
                <Select size="small" text style="width: 70px; margin-left: 5px;" clearable filterable v-model="item.timesVolume[1]">
                  <Option v-for="(t, i) in measurement" :key="i" :label="t" :value="t"></Option>
                </Select>
              </div>
            </div>
          </div>
          <span class="del" @click="delMedichine(key)"><i class="fmico fmico-cha"></i>删除</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import GroupMixins from './mixins'
import { InputNumber, Select, Option } from 'view-design'

Vue.component('InputNumber', InputNumber)
Vue.component('Select', Select)
Vue.component('Option', Option)

export default {
  mixins: [GroupMixins]
}
</script>
