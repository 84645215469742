import { usage, usageFrequency, measurement } from '@/views/medicine/lib'

export default {
  props: {
    index: { type: Number, default: null },
    data: { type: Object, default: null }
  },
  computed: {
    usage () {
      return usage
    },
    usageFrequency () {
      return usageFrequency
    },
    measurement () {
      return measurement
    }
  },
  methods: {
    delMedichine (index) {
      this.data.delMedichine(index)
    }
  }
}