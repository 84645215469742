<style lang="less" scoped>
  @import url('./styles.less');
</style>

<template>
  <div class="medicine-group bags-medicine-group" v-if="data && data.list">
    <div class="title">
      <span class="num" v-if="index !== null">{{index + 1}}</span>
      <span class="type-name">中药颗粒瓶装</span>
    </div>
    <div class="items">
      <div class="item" v-for="(item, key) in data.list" :key="key">
        <div class="name">{{item.medichine.mainbodyMedicine ? item.medichine.mainbodyMedicine.medicineName : item.medichine.name}}</div>
        <div class="row">
          <div class="field-item">
            <span class="field">价格：</span>
            <span class="price">¥{{item.amount = (item.volume * item.price).toFixed(2)}}</span>
            <span class="field">（¥{{item.price}} * {{item.volume}}）</span>
          </div>
          <div class="field-item">
            <span class="field">当量：</span>
            <InputNumber size="small" :min="1" v-model="item.volume" controls-outside />
            <span class="unit">克</span>
          </div>
        </div>
        <div class="params">
          <div style="flex: 1;"></div>
          <span class="del" @click="delMedichine(key)"><i class="fmico fmico-cha"></i>删除</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <span class="field">用药频率：</span>
      <fm-select v-model="data.usageFrequency" @change="data.setUsageFrequency()" clearable filterable placeholder="请选择用药频率">
        <fm-option v-for="(t, i) in usageFrequency" :key="i" :value="t" :label="t"></fm-option>
      </fm-select>
      <span class="value">共</span>
      <InputNumber size="small" v-model="data.timesNum" controls-outside />
      <span class="unit">剂</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import GroupMixins from './mixins'
import { InputNumber } from 'view-design'

Vue.component('InputNumber', InputNumber)

export default {
  mixins: [GroupMixins]
}
</script>
