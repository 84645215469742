<style scoped lang="less">
  .medicine-search-block {
    height: 100%;
    width: 100%;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .tabs {
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 0 5px;
    box-sizing: border-box;
    div {
      word-break: keep-all;
      white-space: wrap;
      padding: 0 10px;
      line-height: 50px;
      cursor: pointer;
      box-sizing: border-box;
      transition: all .3s;
      font-size: 15px;
      color: #999;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #2E6BE5;
        opacity: 0;
        position: absolute;
        transition: opacity .3s;
        left: 0;
        bottom: 0;
      }
      &:hover {
        color: #2E6BE5;
      }
      &.active {
        color: #2E6BE5;
        position: relative;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .top {
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
  }
  .list {
    flex: 1;
    height: 0;
    overflow: auto;
    display: flex;
    align-content: baseline;
    flex-wrap: wrap;
    .item {
      padding: 10px;
      box-sizing: border-box;
      width: 25%;
      display: flex;
      position: relative;
      display: flex;
      flex-direction: column;
      transition: all .3s;
      &:hover {
        background-color: rgba(46, 107, 229, .1);
      }
      .row {
        position: relative;
        display: flex;
        align-items: center;
        .btn {
          margin-left: 5px;
          font-size: 12px;
          cursor: pointer;
        }
        & + .row {
          margin-top: 5px;
        }
      } 
      .name {
        flex: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
        color: #000;
        font-size: 15px;
      }
      .packing {
        white-space: nowrap;
        color: #000;
        font-size: 14px;
      }
      .price {
        font-size: 13px;
        color: red;
      }
      .volume {
        margin-left: 5px;
        color: #2E6BE5;
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgba(46, 107, 229, .05);
        padding: 2px;
      }
      .manufacturer {
        font-size: 12px;
        color: #888;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .icon-add {
    cursor: pointer;
    color: #FFF;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    background-color: #2E6BE5;
    text-align: center;
    line-height: 23px;
    font-weight: bold;
    margin-left: 5px;
    font-size: 12px;
  }
</style>

<template>
  <div class="medicine-search-block">
    <div class="top">
      <div class="tabs">
        <div v-for="item in types" :class="{active: activeTag && activeTag.value === item.value}" @click="switchTag(item)" :key="item.value">{{item.cate}}</div>
      </div>
      <fm-input-new v-model="searchKey" @input="onSearch" style="width: 200px;margin-right: 10px;" placeholder="请输入药品名称">
        <i slot="prefix" style="margin-left: 5px;" class="iconfont icon-search"></i>
      </fm-input-new>
    </div>
    <div class="list" v-loadingx="loading.load" ref="list">
      <div class="item" v-for="item in dataList" :key="item.id">
        <div class="row">
          <Tooltip :delay="1000" style="flex: 2;" transfer placement="top" :content="'药品名称：' + item.name">
            <span class="name">{{item.name}}</span>
          </Tooltip>
          <Tooltip :delay="1000" transfer placement="top" :content="item.packingText" v-if="item.packing">
            <span class="packing">{{item.packing}}</span>
          </Tooltip>
        </div>
        <div class="row">
          <template v-if="item.mainbodyMedicine">
            <span class="price">¥{{item.mainbodyMedicine.price || 0}}</span>
            <span class="volume" :title="item.inventory ? item.inventory.totalVolume : '无库存'">{{item.inventory ? item.inventory.totalVolume : '无库存'}}</span>
            <span class="btn" @click="openPut(item)" style="color: orange;" v-if="!processed.includes(item.id) && (!item.inventory || item.inventory.totalVolume <= 0)">[入库]</span>
          </template>
          <template v-else>
            <span class="price">¥{{item.price || 0}}</span>
            <span class="btn" v-if="!processed.includes(item.id)" @click="openAdd(item)" style="color: red;">[新增]</span>
          </template>
          <span class="btn" style="color: blue;cursor: default;" v-if="processed.includes(item.id)">[已处理]</span>
          <span style="flex: 1;margin-left: 5px;display: flex;justify-content: flex-end;">
            <i @click="$emit('choose', item, activeTag)" class="iconfont icon-add"></i>
          </span>
        </div>
        <div class="row">
          <span class="manufacturer">{{item.manufacturer}}</span>
        </div>
      </div>
    </div>
    <fm-page
      style="margin: 10px auto;"
      @change="onPageChange"
      :current="page.num"
      :total="page.total"
      :page-size="page.size"
    />

    <fm-modal :value="status.add" title="新增药品" width="70vw" @cancel="status.add = false" :mask-closable="false">
      <PharmacyForm v-if="status.add" :medicine-id="chooseData.id" @submit="onAdd" />
    </fm-modal>

    <fm-modal :value="status.put" title="入库" width="400px" @cancel="status.put = false" :mask-closable="false">
      <PharmacyPut v-if="status.put" @success="onPut" @close="status.put = false" :data="chooseData" />
    </fm-modal>
  </div>
</template>

<script>
import { mainbodyMedicineRequest } from '@/api'
import { types, getPackingName } from '../lib'
import PharmacyForm from '@/views/pharmacy/form.vue'
import PharmacyPut from '@/views/pharmacy/put.vue'

import Vue from 'vue'
import { Tooltip } from 'view-design'
Vue.component('Tooltip', Tooltip)

let timer = null

export default {
  components: { PharmacyForm, PharmacyPut },
  props: {
    hide: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      data: [],
      activeTag: null,
      details: null,
      searchKey: null,
      chooseData: null,
      page: {
        num: 1,
        size: 40,
        total: 0
      },
      loading: {
        load: false
      },
      processed: [],
      status: {
        put: false,
        add: false
      }
    }
  },
  computed: {
    getPackingName () {
      return getPackingName
    },
    types () {
      return types.filter(v => !this.hide.length || !this.hide.includes(v.action))
    },
    dataList () {
      return this.data
    }
  },
  watch: {
  },
  methods: {
    openAdd (data) {
      this.chooseData = data
      this.status.add = true
    },
    openPut (data) {
      this.chooseData = { medicineId: data.id }
      this.status.put = true
    },
    async onAdd (data) {
      const res = await mainbodyMedicineRequest.add(data)
      this.processed.push(res.medicineId)
      this.$notice.success('添加成功')
      this.status.add = false
    },
    onPut ({ medicineId }) {
      this.processed.push(medicineId)
      this.status.put = false
    },
    onPageChange (page) {
      this.page.num = page
      this.loadData()
    },
    switchTag (item) {
      this.activeTag = item
      this.page.num = 1
      this.loadData()
      this.$emit('tag-change', item)
    },
    onSearch () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.page.num = 1
        this.loadData()
      }, 800)
    },
    async loadData () {
      this.loading.load = true

      const parm = {
        pageNum: this.page.num,
        pageSize: this.page.size,
        medicineType: this.activeTag.value
      }

      if (this.searchKey) {
        parm.medicineName = this.searchKey
      }
      
      this.processed = []
      const res = await mainbodyMedicineRequest.getBaseAll(parm)
      this.data = res.data.map(v => {
        v.packingText = getPackingName(v.type) + '：' + v.packing
        return v
      })
      this.page.total = res.total
      this.loading.load = false
      this.$refs.list.scrollTop = 0
    },
    showDetails (data) {
      this.details = data
    }
  },
  mounted () {
    this.activeTag = this.types[0]
    this.loadData() 
  }
}
</script>
